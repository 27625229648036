<template>
  <div class="page page-admin-roles-list">
    <PageHeader
    :icon="$t('ROLE.ICON')"
    :title="$t('ROLE.TITLES')"
    back="/settings/"
    >
      <template slot="nav">
        <small><strong>{{$tc('ROLE.TITLE_COUNT',count)}}</strong></small>
      </template>

      <v-menu
        offset-y
        nudge-bottom="5px"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
              <v-btn
                fab
                small
                depressed
                color="primary"
                v-bind="{...attrsMenu, ...attrsTip}"
                v-on="{...onMenu, ...onTip}"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.MORE_ACTIONS') }}
          </v-tooltip>
        </template>

        <v-list nav dense>
          <v-list-item link @click="newItem">
            <v-list-item-icon><v-icon size="20">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t('COMMON.NEW') }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="success"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.ADD') }}
      </v-tooltip>

    </PageHeader>
    <Side
      component="forms/role/compact/FormRoleCompact"
      :ids="(selected || []).map(v => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <!-- <Dialog
      :icon="selected.length ? 'mdi-pencil' : 'mdi-plus-thick'"
      :title="$t('COMMON.EDIT') + ': ' + $tc('USER_PERMISSION.TITLE_COUNT', selected.length)"
      component="form/FormAssignUserPermission"
      :items="selected || []"
      :props="{type:'role'}"
      :value="showDialogPermissionEdit"
      @close="showDialogPermissionEdit = false"
      @update="loadItems"
      @select="selectItems"
    /> -->
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="title"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
  <Loading :value="loading" />
  <List
    :items="items"
    :count="count"
    :offset="offset"
    :limit="limit"
    :pagination="true"
    :selected="selected"
    @applyFilters="applyFilters"
    @resetFilters="resetFilters"
    @select="selectItems"
    @page="changePage"
    @click="selectItem"
    @dblclick="editItem"
    @reload="searchItems"
  >
    <template v-slot:header="">
      <div class="col-3">
        <v-text-field
          rounded
          ref="where.title"
          hide-details
          outlined
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('COMMON.SEARCH')"
          @keyup="searchItems"
          @click:clear="searchItems"
        />
      </div>
      <!-- <div>
        <v-btn-toggle
          ref="where.methods"
          @change="searchItems"
          dense
          v-model="methods"
          multiple
        >
          <v-btn small value="cpc">cpc</v-btn>
          <v-btn small value="cpm">cpm</v-btn>
          <v-btn small value="credit">credit</v-btn>
          <v-btn small value="organic">organic</v-btn>
        </v-btn-toggle>
      </div>

      <v-spacer />

      <div>
        <v-btn-toggle
          ref="where.status"
          @change="searchItems"
          dense
          v-model="status"
          multiple
        >
          <v-btn small icon><v-icon size="14" color="error">mdi-circle</v-icon></v-btn>
          <v-btn small icon><v-icon size="14" color="success">mdi-circle</v-icon></v-btn>
        </v-btn-toggle>
      </div> -->
    </template>

    <template v-slot:filters="">
        <v-text-field
          ref="where.level"
          label="Level"
          type="number"
        />
        <!-- @keyup="onLevelChange" -->
    </template>

    <template v-slot:selections="">
      <v-col cols="12" class="pt-0 pl-0">
        <div><small><strong>{{ $tc('COMMON.SELECTED', +selected.length) }}</strong></small></div>
        <v-btn
          small
          rounded
          depressed
          color="primary"
          class="mr-2"
          @click="editItems"
        >
          <v-icon size="20" class="mr-2">mdi-pencil</v-icon>
          {{ $t('COMMON.EDIT') }}
        </v-btn>
        <v-btn
          small
          rounded
          depressed
          color="error"
          class="mr-2"
          @click="removeItems"
        >
          <v-icon size="20" class="mr-2">mdi-delete</v-icon>
          {{ $t('COMMON.REMOVE') }}
        </v-btn>
        <v-btn
          small
          rounded
          depressed
          color="secondary custom black--text"
          @click="selectItems([])"
        >
          <v-icon size="20" class="mr-2">mdi-close</v-icon>
          {{ $t('COMMON.CANCEL') }}
        </v-btn>
      </v-col>
    </template>

    <template v-slot:item-list="item">
      <v-list-item-action>
        <img :src="item.image_url" width="40" >
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title class="primary--text"><strong>{{ item.title }}</strong></v-list-item-title>
        <v-list-item-subtitle>{{ $moment(item.updated_at).fromNow() }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          icon
          class="mr-2"
          @click.stop="removeItem(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          icon
          class="mr-2"
          @click.stop="editPermissionItem(item)"
        >
          <v-icon>mdi-key-chain-variant</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn
          icon
          class="mr-2"
          @click.stop="editItem(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-icon
          size="14"
          :color="item.status > 0 ? 'success' : 'error'"
          class="ml-2"
        >
          mdi-circle
        </v-icon>
      </v-list-item-action>
    </template>
  </List>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import DialogRemove from '@/components/DialogRemove'
import Side from '@/components/Side'
import Dialog from '@/components/Dialog'
import List from '@/components/List'
import PageHeader from '@/components/PageHeader'

export default {
  name: 'PageSettingsXadminRoles',
  components: {
    Loading,
    Side,
    // Dialog,
    DialogRemove,
    List,
    PageHeader,
  },
  data: () => ({
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [],
    methods: [],
  }),

  computed: {
    paramsId () {
      return this.$router.currentRoute.params.id || undefined
    },

    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },
  },

  mounted () {
    this.$queryFilters().get()
    this.searchItems()
    if (this.itemId) {
      this.selectItems([{id: this.itemId}])
      this.editItems()
    }
  },

  methods: {

    // Request
    searchItems (element) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 100)
    },

    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
        where: {
          status: [1]
        }
      })
      this.$emit('childData', { queryList: query })
      this.$services.api_user.role.search(query)
        .then(response => {
          if (response.data) {
            this.items = response.data.items
            this.count = response.data.count
            this.offset = response.data.offset
            this.limit = response.data.limit
          } else {
            this.items = []
            this.count = 0
            this.offset = 0
            this.limit = 0
          }
          this.updateSelected()
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_user.role.remove({ where: { id: ids }})
        .then(response => {
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
    },

    updateSelected () {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    // Mutliple items

    removeItems () {
      this.showDialogRemove = true
    },

    editItems () {
      this.showEdit = true
    },

    selectItem (item) {
      for (let i = 0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }
      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push(`/settings/xadmin/roles/`)
    },

    // Single item

    newItem () {
      this.selected = []
      this.showEdit = true
    },

    editItem (item) {
      this.$router.push(`/settings/xadmin/roles/?edit=${item.id}`)
      this.selected = [item]
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    goToItem (item) {
      this.$router.push(`/settings/xadmin/roles/${item.id}`)
    },

    // filter methods
    resetFilters () {
      this.applyFilters()
    },

    applyFilters () {
      this.searchItems()
    },
  }
}
</script>
